import React from 'react';

import {Flex, Box, Link, Text, Image} from 'rebass';
import PropTypes from 'prop-types';

import {mediaQuery} from '@renofi/utils';
import {Panel, Button, logoCottage} from '@renofi/components';
import {renofiBlue} from '@renofi/theme';

import trust from './img/trust.svg';
import search from './img/search.svg';
import contract from './img/contract.svg';
import ProsItemContent from './ProsItemContent';
import {
  ProsWrapper,
  ProsReverse,
  ProsItem,
  CentralProsItem,
  ProsItemLeft,
  ProsSuccess,
  ProsSuccessItem,
  ProsSuccessItemTitle,
  ProsSuccessItemDescription,
} from './styled';

const CottageFindPros = ({handleClick}) => {
  const scrollToSection = (event, id) => {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <Panel
      css={{
        borderRadius: 16,
        backgroundColor: '#fff',
        border: '1px solid rgba(0, 96, 153, 0.15)',
        boxShadow: 'none !important',
        overflow: 'hidden',
        marginTop: [0, '25px'],
      }}
      noPadding={true}>
      <Flex
        alignItems="center"
        backgroundColor="#fee"
        height={['auto', '50px']}
        width="100%"
        p="10px">
        <Box textAlign="center" width="100%">
          <strong>FREE Service</strong> - No commitment required.{' '}
          <Link
            href="#"
            role="button"
            fontSize="16px"
            color={renofiBlue}
            onClick={(e) => scrollToSection(e, 'faq_section_title')}>
            See why
          </Link>
        </Box>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        p={['20px', '40px']}
        flexDirection="column"
        css={mediaQuery({gap: ['24px', '30px']})}>
        <Text
          fontSize={['32px', '40px']}
          lineHeight="normal"
          textAlign="center">
          Find the right pros for your project!
        </Text>
        <Text
          fontSize="20px"
          lineHeight="normal"
          opacity="0.8"
          textAlign="center"
          px={[0, '100px']}>
          We'll help you find and get bids from verified and proven local
          designers, architects, contractors and other pros for your project.
        </Text>
        <Button primary onClick={handleClick}>
          Book a Call to learn more
        </Button>
        <ProsWrapper>
          <ProsReverse>
            <ProsItem>
              <ProsItemContent
                name="Javier"
                company="General Contractor"
                avatar="https://ucarecdn.com/4c5b5d5f-3fa7-4444-98ce-198fc9037716/Aaron_2.png"
                price="$100,000"
                label="matched"
                projects="27"
              />
            </ProsItem>
            <CentralProsItem>
              <ProsItemContent
                name="Aaron"
                company="General Contractor"
                avatar="https://ucarecdn.com/086e8e80-cc5a-4206-bdd1-222e71429549/Aaron.png"
                price="$118,000"
                label="selected"
                projects="32"
              />
            </CentralProsItem>
          </ProsReverse>
          <ProsItemLeft>
            <ProsItemContent
              name="Evelyn"
              company="General Contractor"
              avatar="https://ucarecdn.com/74049165-9a12-49c9-a650-e61ed97a5ebb/Evelyn_2.png"
              price="$120,000"
              label="matched"
              projects="18"
            />
          </ProsItemLeft>
        </ProsWrapper>

        <ProsSuccess>
          <ProsSuccessItem>
            <Image src={trust} />
            <Flex flexDirection="column" flex="1">
              <ProsSuccessItemTitle>Pros you can trust</ProsSuccessItemTitle>
              <ProsSuccessItemDescription>
                We do the vetting for you. Save hours by accessing our network
                of trusted providers.
              </ProsSuccessItemDescription>
            </Flex>
          </ProsSuccessItem>
          <ProsSuccessItem>
            <Image src={search} />
            <Flex flexDirection="column" flex="1">
              <ProsSuccessItemTitle>
                Curated matches to the right pros
              </ProsSuccessItemTitle>
              <ProsSuccessItemDescription>
                Choosing the right providers is hard. We connect you with the
                best-fit pros for your project.
              </ProsSuccessItemDescription>
            </Flex>
          </ProsSuccessItem>
          <ProsSuccessItem>
            <Image src={contract} />
            <Flex flexDirection="column" flex="1">
              <ProsSuccessItemTitle>
                Easily compare proposals
              </ProsSuccessItemTitle>
              <ProsSuccessItemDescription>
                Leverage our smart proposal comparison tool to understand
                tradeoffs and exclusions.
              </ProsSuccessItemDescription>
            </Flex>
          </ProsSuccessItem>
        </ProsSuccess>
      </Flex>
      <Flex
        height={['auto', '70px']}
        backgroundColor="#212F49"
        alignItems={['flex-start', 'center']}
        justifyContent="center"
        color="#fff"
        p={['20px', '10px']}
        flexDirection={['column', 'row']}
        style={{gap: '16px'}}
        fontSize={['14px', '16px']}>
        {' '}
        <Image
          verticalAlign="middle"
          src={logoCottage}
          height={[36, 28]}
          marginBottom="5px"
        />
        Cottage is now part of RenoFi. Find the perfect pro and the smartest way
        to finance your project all in one place.
      </Flex>
    </Panel>
  );
};

CottageFindPros.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default CottageFindPros;
