import React from 'react';

import {Text, Flex} from 'rebass';
import PropTypes from 'prop-types';

import {mediaQuery} from '@renofi/utils';
import {renofiBlue} from '@renofi/theme';
import {Panel, Button} from '@renofi/components';

import call from './img/call.png';
import vettedPros from './img/vetted_pros.png';
import devices from './img/devices.png';
import {
  BenefitItem,
  BenefitItemImage,
  BenefitItemTitle,
  BenefitItemDescr,
} from './styled';

const CottageHowItWorks = ({handleClick}) => {
  return (
    <Panel
      css={{
        color: '#fff',
        backgroundColor: renofiBlue,
        padding: ['62px 20px', '62px 35px'],
      }}
      contentCss={{padding: 0}}>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        css={mediaQuery({gap: ['40px', '60px']})}>
        <Text fontSize="40px">How it works</Text>
        <Flex flexDirection={['column', 'row']} style={{gap: '40px'}}>
          <BenefitItem>
            <BenefitItemImage src={call} />
            <BenefitItemTitle>Speak to your Advisor</BenefitItemTitle>
            <BenefitItemDescr>
              Share your vision, budget and timeline.
            </BenefitItemDescr>
          </BenefitItem>
          <BenefitItem>
            <BenefitItemImage src={vettedPros} />
            <BenefitItemTitle>Select vetted pros</BenefitItemTitle>
            <BenefitItemDescr>
              Get matched to local, vetted pros.
            </BenefitItemDescr>
          </BenefitItem>
          <BenefitItem>
            <BenefitItemImage src={devices} />
            <BenefitItemTitle>Receive your bids</BenefitItemTitle>
            <BenefitItemDescr>
              Compare your proposals and choose your pro.
            </BenefitItemDescr>
          </BenefitItem>
        </Flex>
        <Button primary onClick={handleClick}>
          Book a Call to learn more
        </Button>
      </Flex>
    </Panel>
  );
};

CottageHowItWorks.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default CottageHowItWorks;
