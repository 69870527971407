import React from 'react';

import {Image} from 'rebass';
import PropTypes from 'prop-types';

import {RenofiHouseRedIcon} from '@renofi/components';

import AdvisorThumb from '../AdvisorThumb';
import AdvisorName from '../AdvisorName';

import pin from './img/pin.png';
import {LogoWrapper, CardWrapper, AdvisorSubtitle} from './styled';

const AdvisorCard = ({message}) => {
  return (
    <CardWrapper>
      <LogoWrapper>
        <RenofiHouseRedIcon width={32} height={32} />
      </LogoWrapper>

      <LogoWrapper>
        <Image mt={-39} ml={30} src={pin} width={36} />
      </LogoWrapper>

      <AdvisorThumb size={60} />
      <AdvisorName size={20} mt="8px" />
      <AdvisorSubtitle>{message}</AdvisorSubtitle>
    </CardWrapper>
  );
};

AdvisorCard.propTypes = {
  message: PropTypes.string.isRequired,
};

export default AdvisorCard;
