import React from 'react';

import {Flex, Box, Image, Link} from 'rebass';

import {logoCottage} from '@renofi/components';
import {darkBlue} from '@renofi/utils';

const CottageBanner = () => {
  return (
    <Flex
      alignItems="center"
      height="56px"
      backgroundColor={darkBlue}
      px="10px">
      <Box
        color="#fff"
        paddingLeft={[0, 356]}
        textAlign={['center', 'left']}
        fontSize={[14, 16]}
        lineHeight="normal">
        RenoFi has joined forces with&nbsp;
        <Image
          verticalAlign="middle"
          src={logoCottage}
          height={[21, 28]}
          marginBottom="5px"
        />
        &nbsp;to help you find the perfect pro for your project. &nbsp;
        <Link
          href="https://www.renofi.com/cottage-and-renofi-join-forces/"
          fontSize={[14, 16]}
          color="#80CFFF"
          target="_blank">
          Learn more
        </Link>
      </Box>
    </Flex>
  );
};

export default CottageBanner;
