import styled from '@emotion/styled';
import {Flex, Image, Text} from 'rebass';

export const BenefitItem = styled(Flex)({
  flex: '1',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const BenefitItemImage = styled(Image)({
  paddingBottom: '24px',
});

export const BenefitItemTitle = styled(Text)({
  fontSize: '20px',
});

export const BenefitItemDescr = styled(Text)({
  fontSize: '16px',
  opacity: '0.7',
  textAlign: 'center',
});
