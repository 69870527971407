import React from 'react';

import {Flex, Box, Text, Image} from 'rebass';
import PropTypes from 'prop-types';

import paid from './img/paid.svg';
import reward from './img/reward.svg';
import location from './img/location.svg';
import selected from './img/selected.svg';
import {
  AvatarWrapper,
  ProsItemHeader,
  ProsItemDetails,
  ProsItemPriceWrapper,
  ProsItemPrice,
  BlueLabel,
  YellowLabel,
  GreenLabel,
  ProsItemBenefits,
  ProsItemBenefit,
} from './styled';

const ProsItemContent = ({name, company, avatar, price, label, projects}) => {
  return (
    <>
      <ProsItemHeader>
        <Box>
          <AvatarWrapper>
            <Image src={avatar} width="50px" />
          </AvatarWrapper>
        </Box>
        <Flex flexDirection="column" justifyContent="space-evenly">
          <Text fontSize="20px" color="#0E1D3F">
            {name}
          </Text>
          <Text fontSize="14px" color="#0E1D3F">
            {company}
          </Text>
        </Flex>
        <Box>
          {label === 'selected' ? (
            <GreenLabel>
              <Image
                src={selected}
                width="15px"
                height="15px"
                marginBottom="3px"
              />{' '}
              Selected
            </GreenLabel>
          ) : (
            <YellowLabel>Matched</YellowLabel>
          )}
        </Box>
      </ProsItemHeader>
      <ProsItemDetails>
        <ProsItemPriceWrapper>
          <ProsItemPrice>{price}</ProsItemPrice>
          <BlueLabel>
            <Image src={paid} width="15px" height="15px" marginBottom="3px" />{' '}
            Estimate
          </BlueLabel>
        </ProsItemPriceWrapper>
        <ProsItemBenefits>
          <ProsItemBenefit>
            <Image src={reward} />
            {projects} completed projects
          </ProsItemBenefit>
          <ProsItemBenefit>
            <Image src={location} />
            Experienced in your city
          </ProsItemBenefit>
        </ProsItemBenefits>
      </ProsItemDetails>
    </>
  );
};

ProsItemContent.propTypes = {
  name: PropTypes.string,
  company: PropTypes.string,
  avatar: PropTypes.string,
  price: PropTypes.string,
  label: PropTypes.oneOf(['selected', 'matched']),
  projects: PropTypes.string,
};

export default ProsItemContent;
