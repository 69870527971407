import React from 'react';

import PropTypes from 'prop-types';
import DesignModalTitle from 'components/DesignModalTitle';
import {useToggleSurvey} from 'hooks';

import {useUpdateScenario, usePrequalCompleted} from '@renofi/api';
import {constants, useFeatureFlagVariantEnabled} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';
import {FeedbackModal} from '@renofi/modules';
import {isAuthenticated, isFakeLead} from '@renofi/utils/src/lead';

import ScheduleModal from '../../../ScheduleModal';
import LeadDetailsModal from '../../../LeadDetailsModal';
import useModals from '../../../hooks/useModals';

const {SCHEDULE_CALENDAR_FEEDBACK_OPTIONS} = constants;

const ScheduleModals = ({lead, scheduleUtils, designScheduleUtils}) => {
  const {updateScenario} = useUpdateScenario({id: lead?.id});

  const {setModal, modal, prevModal} = useModals();
  const {onScheduleSuccess, isCallScheduled} = scheduleUtils;
  const {onScheduleSuccess: onScheduleSuccessDesign} = designScheduleUtils;
  const {prequalCompleted} = usePrequalCompleted();

  const dashboardSignupEnabled = useFeatureFlagVariantEnabled(
    'dashboard-sign-up',
    'challenger',
  );

  const validateDetails = (leadDetails) => {
    return Boolean(
      leadDetails.email &&
        leadDetails.firstName &&
        leadDetails.lastName &&
        leadDetails.phone,
    );
  };
  const isAllDetails = validateDetails(lead) && !isFakeLead(lead);

  useToggleSurvey(modal);

  return (
    <>
      <ScheduleModal
        visible={isAllDetails && modal === constants.MODAL_TYPES.SCHEDULE}
        title={'Great! When should your advisor call you?'}
        subtitle={'Please pick a time from the calendar below.'}
        onSuccess={(data) => {
          onScheduleSuccess(data);
          sendEvent('Dashboard/Schedule-Success');
        }}
        onClose={() => setModal(constants.MODAL_TYPES.FEEDBACK)}
        onEdit={() => setModal(constants.MODAL_TYPES.LEAD_DETAILS)}
        editEnabled={isAllDetails}
      />
      <ScheduleModal
        visible={isAllDetails && modal === constants.MODAL_TYPES.READY}
        title={'Great! When should your advisor call you?'}
        subtitle={'Please pick a time from the calendar below.'}
        onSuccess={(data) => {
          onScheduleSuccess(data);
          sendEvent('Dashboard/Ready-Schedule-Success', {
            readyToSchedule: 'yes',
          });
        }}
        onClose={() => setModal(constants.MODAL_TYPES.FEEDBACK)}
        onEdit={() => setModal(constants.MODAL_TYPES.LEAD_DETAILS)}
        editEnabled={isAllDetails}
      />
      <ScheduleModal
        visible={isAllDetails && modal === constants.MODAL_TYPES.NOT_READY}
        title={
          'No problem, when would be a better time to have your advisor reach out?'
        }
        subtitle={'Please pick a time from the calendar below.'}
        onSuccess={(data) => {
          onScheduleSuccess(data);
          sendEvent('Dashboard/Ready-Schedule-Success', {
            readyToSchedule: 'no',
          });
        }}
        onClose={() => setModal(constants.MODAL_TYPES.FEEDBACK)}
        onEdit={() => setModal(constants.MODAL_TYPES.LEAD_DETAILS)}
        editEnabled={isAllDetails}
      />
      <ScheduleModal
        visible={
          isAllDetails && modal === constants.MODAL_TYPES.SKIP_PREQUALIFICATION
        }
        title={'Connect with your advisor to learn more or move forward.'}
        subtitle={
          'Pick a time from the calendar below and your advisor will call you.'
        }
        onSuccess={(data) => {
          onScheduleSuccess(data);
          sendEvent('Dashboard/Request-Prequal-Schedule-Success', {
            requestPrequal: 'skip',
          });
        }}
        onClose={() => setModal(constants.MODAL_TYPES.FEEDBACK)}
        onEdit={() => setModal(constants.MODAL_TYPES.LEAD_DETAILS)}
        editEnabled={isAllDetails}
      />
      <ScheduleModal
        visible={
          isAllDetails &&
          modal === constants.MODAL_TYPES.REQUEST_PREQUALIFICATION
        }
        title={'Great, let’s get you connected to an advisor and get started.'}
        subtitle={
          'Pick a time from the calendar below and your advisor will call you.'
        }
        onSuccess={(data) => {
          onScheduleSuccess(data);
          sendEvent('Dashboard/Request-Prequal-Schedule-Success', {
            requestPrequal: 'request',
          });
          updateScenario({
            variables: {prequalLetterRequested: true, id: lead.id},
          });
        }}
        onClose={() => setModal(constants.MODAL_TYPES.FEEDBACK)}
        onEdit={() => setModal(constants.MODAL_TYPES.LEAD_DETAILS)}
        editEnabled={isAllDetails}
      />
      {!isCallScheduled && (
        <FeedbackModal
          visible={modal === constants.MODAL_TYPES.FEEDBACK}
          options={SCHEDULE_CALENDAR_FEEDBACK_OPTIONS}
          onSubmit={({closeCalendarFeedback, closeCalendarFeedbackDetails}) => {
            sendEvent('Dashboard/Close-Calendar-Feedback-Submitted', {
              closeCalendarFeedback,
              closeCalendarFeedbackDetails,
            });
          }}
          onClose={() => {
            sendEvent('Dashboard/Close-Calendar-Feedback-Closed');
            setModal(constants.MODAL_TYPES.NONE);
          }}
        />
      )}
      <LeadDetailsModal
        visible={
          (!isAllDetails &&
            [
              constants.MODAL_TYPES.SCHEDULE,
              constants.MODAL_TYPES.READY,
              constants.MODAL_TYPES.NOT_READY,
              constants.MODAL_TYPES.SKIP_PREQUALIFICATION,
              constants.MODAL_TYPES.REQUEST_PREQUALIFICATION,
            ].includes(modal)) ||
          modal === constants.MODAL_TYPES.LEAD_DETAILS
        }
        lead={lead}
        fakeLead={isFakeLead(lead)}
        title="Let’s schedule your 15 min call with a RenoFi Advisor!"
        cta="See available times"
        onSubmit={async () => {
          if (dashboardSignupEnabled && isAuthenticated(lead?.id)) {
            setModal(constants.MODAL_TYPES.NONE);
            await prequalCompleted({variables: {scenarioId: lead.id}});
            return;
          }

          setModal(
            modal === constants.MODAL_TYPES.LEAD_DETAILS ? prevModal : modal,
          );
        }}
        onCancel={() =>
          setModal(isAllDetails ? prevModal : constants.MODAL_TYPES.NONE)
        }
        onClose={() =>
          setModal(isAllDetails ? prevModal : constants.MODAL_TYPES.NONE)
        }
        onBrowserAction={() => setModal(constants.MODAL_TYPES.NONE)}
      />
      <LeadDetailsModal
        visible={modal === constants.MODAL_TYPES.DESIGN_LEAD_DETAILS}
        lead={lead}
        title={<DesignModalTitle />}
        subtitle="This call will help us understand the renovation you are considering, where you are in the process and why you want to renovate. We’ll walk you through the RenoFi design process, and present examples of the design package you would receive if you decide to proceed."
        designLead={true}
        onSubmit={() => setModal(constants.MODAL_TYPES.SCHEDULE_DESIGN_CALL)}
        onCancel={() => setModal(constants.MODAL_TYPES.NONE)}
        onClose={() => setModal(constants.MODAL_TYPES.NONE)}
        onBrowserAction={() => setModal(constants.MODAL_TYPES.NONE)}
      />
      <ScheduleModal
        visible={
          isAllDetails && modal === constants.MODAL_TYPES.SCHEDULE_DESIGN_CALL
        }
        calendarId="#design-call-calendar"
        title={<DesignModalTitle />}
        subtitle=""
        callType="design"
        editEnabled={false}
        onSuccess={(data) => {
          onScheduleSuccessDesign(data);
          sendEvent('Dashboard/Design-Call-Schedule-Success');
        }}
        onClose={() => setModal(constants.MODAL_TYPES.NONE)}
      />
    </>
  );
};

ScheduleModals.propTypes = {
  lead: PropTypes.object,
};

export default ScheduleModals;
