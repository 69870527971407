import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {setAnalyticsIdentify} from '@renofi/analytics';
import {getStoredLead, getStoredPrefixedLead} from '@renofi/utils/src/lead';

import {UPDATE_SCENARIO} from '../mutations/updateScenario';

export default function useUpdateScenario({onCompleted = noop, id} = {}) {
  const [updateScenario, {data, loading, error}] = useMutation(
    UPDATE_SCENARIO,
    {
      onCompleted: ({updateScenario}) => {
        if (updateScenario.lead) {
          const existingLead = getStoredLead();
          const existingPrefixedLead = getStoredPrefixedLead(id);
          const identityData = {
            ...existingLead,
            ...existingPrefixedLead,
            ...updateScenario.lead,
          };
          setAnalyticsIdentify(identityData, {
            loanProducts: updateScenario.loanProductMatches,
          });
        }
        onCompleted(updateScenario);
      },
    },
  );
  const response = data?.updateScenario;
  return {updateScenario, response, loading, error};
}
