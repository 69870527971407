import {Flex, Box} from 'rebass';
import styled from '@emotion/styled/macro';

import {mediaQuery} from '@renofi/utils';

export const PaddedWrapper = styled(Box)(() =>
  mediaQuery({
    padding: ['32px 16px 16px 16px', '30px 30px 30px 30px'],
    height: '99%',
  }),
);

export const CPPopup = styled(Flex)(() =>
  mediaQuery({
    position: 'fixed',
    display: 'block',
    width: '100%',
    height: '100%',

    top: 0,
    left: 0,
    padding: [0, '30px'],
    overflowY: 'scroll',
    zIndex: 10000000001,
    background: 'rgba(0, 0, 0, 0.65)',

    '& #cp-container': {
      position: 'relative',
      width: ['100%', '50%'],
      maxWidth: 920,
      minWidth: ['100%', 660],
      minHeight: ['100%', 700],
      height: ['calc(100% - 170px)', '100%'],
      margin: 'auto',
      border: '1px solid #E3E4E4',
      borderRadius: [0, '8px'],
      backgroundColor: '#fff',

      '& .chilipiper-title': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        fontWeight: 700,
        fontSize: '28px',
        lineHeight: ['35px', '42px'],
        color: '#212F49',

        '& .subtitle': {
          marginTop: '20px',
          fontSize: ['18px', '20px'],
          lineHeight: ['25px', '35px'],
          fontWeight: 400,
          color: '#737373',
        },
      },
      '& #calendar': {
        height: '100%',
      },
      '& .chilipiper-popup': {
        marginTop: '10px',
        position: 'static',
        backgroundColor: 'transparent',
        '& iframe': {
          border: '1px solid #D8D8D8',
        },
      },
    },
  }),
);
