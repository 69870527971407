import {useState} from 'react';

import {useReactiveVar} from '@apollo/client';

import {useLocalStorage} from '@renofi/utils';
import {chilipiper} from '@renofi/analytics';

import {leadVar} from '../api/cache';

export default function useSchedule(callType = '') {
  const lead = useReactiveVar(leadVar);
  const [_, setScheduledDetails] = useLocalStorage(
    `renofi:${callType}scheduledAt`,
    null,
  );
  const scheduledAtString = window.localStorage.getItem(
    `renofi:${callType}scheduledAt`,
  );
  const scheduleDetails =
    scheduledAtString === 'undefined' ? '' : JSON.parse(scheduledAtString);
  const {start, end} = scheduleDetails || {};
  const today = new Date();
  const callDetails = start && end && chilipiper.parseCallDetails(start, end);
  const isScheduleEnded = today > end;
  const [isCallScheduled, setIsCallScheduled] = useState(
    Boolean(callDetails) && !isScheduleEnded,
  );
  function onScheduleSuccess({slot}) {
    setScheduledDetails(slot);
    setIsCallScheduled(true);
  }

  function appendCalendar({onClose, onSuccess, calendarId = '#calendar'}) {
    chilipiper.initSchedule({
      lead,
      domElement: calendarId,
      router: chilipiper.getRouterByLead(lead),
      analyticsPrefix: 'Dashboard',
      onClose,
      onSuccess,
    });
  }

  return {
    callDetails,
    isCallScheduled,
    onScheduleSuccess,
    appendCalendar,
  };
}
