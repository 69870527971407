import {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';
import {Route, Switch} from 'react-router-dom';
import {ThemeProvider} from '@emotion/react';

import {Analytics, Auth} from '@renofi/components';
import {
  breakpoint,
  constants,
  isQueryParamSet,
  useCookies,
  useFeatureFlagEvents,
  useLocalStorage,
  useScrollToTopBetweenRoutes,
  useUTMTags,
} from '@renofi/utils';
import {initGooglePlaces} from '@renofi/utils/src/places';
import {useUnleashClient} from '@renofi/utils/src/feature-flags';

import {leadVar, loanProductsVar} from '../api/cache';
import {useAutoSchedule} from '../hooks';

import App from './App';

const theme = {
  breakpoints: [`${breakpoint}px`],
};

const AppWrapper = () => {
  const lead = useReactiveVar(leadVar);
  const [scheduleStorage] = useLocalStorage('renofi:schedule');
  const loanProducts = useReactiveVar(loanProductsVar);

  const isScheduleParam = isQueryParamSet('schedule') || scheduleStorage;

  const {persistUTMTags} = useUTMTags(constants.UTM_COOKIE_KEYS.renofi);
  const {cookie: cookieId} = useCookies('renofi_id');
  const unleashClient = useUnleashClient();

  useFeatureFlagEvents(unleashClient, 'Dashboard');

  useScrollToTopBetweenRoutes();

  useAutoSchedule(lead);

  useEffect(() => {
    persistUTMTags();
  }, []);

  useEffect(() => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    if (apiKey) setTimeout(() => initGooglePlaces(apiKey), 5000);
  }, []);

  return (
    <Analytics lead={lead} loanProducts={loanProducts}>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/auth">
            <Auth failedPath={`/${cookieId}/loan-options`} />
          </Route>
          <Route path={['/:id', '/']}>
            <App cookieId={cookieId} openWithSchedule={isScheduleParam} />
          </Route>
        </Switch>
      </ThemeProvider>
    </Analytics>
  );
};

export default AppWrapper;
