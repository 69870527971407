import React, {useEffect} from 'react';

import {Text} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {sendEvent} from '@renofi/analytics';
import {Faqs} from '@renofi/components';
import {CottageFindPros, CottageHowItWorks} from '@renofi/modules';

import {Page} from '../components';
import {leadVar} from '../api/cache';

import faqs from './faqs';

const FindContractor = () => {
  const lead = useReactiveVar(leadVar);
  const {city, state, streetAddress, zipCode} = lead.location ?? {};
  const stateZip = [state, zipCode].filter(Boolean).join(' ');

  const formattedLocation = [streetAddress, city, stateZip]
    .filter(Boolean)
    .join(', ');

  useEffect(() => sendEvent('Dashboard/Find-A-Contractor-Page'), []);

  function handleClick() {
    sendEvent('Dashboard/Find-A-Contractor-Clicked');

    const urlParams = new URLSearchParams({
      firstname: lead?.firstName || '',
      lastname: lead?.lastName || '',
      email: lead?.email || '',
      phone: lead?.phone || '',
      address: formattedLocation,
    });
    window.open(
      `https://cotta.ge/book-a-call?utm_source=renofi&utm_medium=referral&utm_campaign=renofidashboard&utm_content=&${urlParams.toString()}`,
    );
  }

  return (
    <Page showFooterLinks>
      <CottageFindPros handleClick={handleClick} />
      <CottageHowItWorks handleClick={handleClick} />
      <Text fontSize="32px" paddingTop="40px" id="faq_section_title">
        FAQs
      </Text>
      <Faqs faqs={faqs} />
    </Page>
  );
};

export default FindContractor;
