import React from 'react';

import {Flex, Box, Text, Image} from 'rebass';
import {useLocation} from 'react-router-dom';

import {useFlag} from '@renofi/utils/src/feature-flags';
import {useFeatureFlagVariantEnabled} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';
import {SkeletonFlex, SkeletonText, Toggle} from '@renofi/components';
import {isAuthenticated} from '@renofi/utils/src/lead';

import {logout, redirectToLogin} from '../../utils';
import {useSchedule} from '../../hooks';
import ScheduleButton from '../Schedule/ScheduleButton';
import RenoFiDesignCard from '../RenoFiDesignCard';

import {ReactComponent as LoginIcon} from './img/login.svg';
import {ReactComponent as LogoutIcon} from './img/logout.svg';
import {
  NavbarWrapper,
  Navigation,
  NavigationItem,
  NavigationItemLabelWrapper,
  NavigationItemSubtitle,
  NavigationIcon,
  NavigationLink,
  StyledLink,
} from './styled';
import CompanyLogos from './CompanyLogos';

const NavbarDesktop = ({
  id,
  allowSchedule,
  openScheduleModal,
  navOptions,
  loading,
}) => {
  const location = useLocation();
  const isRenoFiDesignEnabled = useFlag('dashboard-renofi-design');
  const isLoanOfficerEnabled = useFlag('dashboard-loan-officer');
  const isDashboardSignupEnabled =
    useFeatureFlagVariantEnabled('dashboard-sign-up');
  const {isCallScheduled} = useSchedule();
  const advisorTitle = isLoanOfficerEnabled
    ? 'licensed loan officer'
    : 'RenoFi advisor';

  const showDesignCard =
    !isDashboardSignupEnabled && isRenoFiDesignEnabled && isCallScheduled;

  return (
    <NavbarWrapper>
      <Flex
        css={{height: '100%'}}
        flexDirection="column"
        justifyContent="space-between">
        <Box>
          <CompanyLogos />

          <Toggle
            show={
              allowSchedule &&
              !loading &&
              !location.pathname.includes('/find-contractor')
            }>
            <Box pt={60} px={20}>
              <Text fontSize={16} mb={2}>
                Talk to your {advisorTitle}
              </Text>
              <ScheduleButton
                css={{padding: '11px 29px 15px 14px'}}
                onClick={openScheduleModal}
              />
            </Box>
          </Toggle>
          <Toggle show={loading}>
            <SkeletonText
              style={{opacity: 0.5}}
              animate
              skeletonHeight={24}
              px={16}
              pt={60}
            />
            <SkeletonText
              style={{opacity: 0.5}}
              animate
              skeletonHeight={42}
              px={16}
              pt={16}
            />

            <SkeletonFlex
              style={{opacity: 0.5, marginBottom: 6}}
              animate
              count={6}
              skeletonHeight={54}
              mt={36}
              px={16}
              pt={16}
            />
          </Toggle>

          <Toggle show={!loading}>
            <Navigation>
              <ul style={{padding: 0}}>
                {navOptions.map((option, index) => (
                  <NavigationLink
                    key={index}
                    isActive={(match) => match?.url.includes(option.path)}
                    onClick={() => {
                      sendEvent(option.event);
                    }}
                    to={`/${id}/${option.path}`}>
                    <NavigationItem>
                      <NavigationIcon>
                        <Image src={option.icon} />
                      </NavigationIcon>
                      <NavigationItemLabelWrapper>
                        {option.labelDesktop}
                        {option.subTitleDesktop && (
                          <NavigationItemSubtitle>
                            {option.subTitleDesktop}
                          </NavigationItemSubtitle>
                        )}
                      </NavigationItemLabelWrapper>
                    </NavigationItem>
                  </NavigationLink>
                ))}
              </ul>
            </Navigation>
          </Toggle>

          {showDesignCard && <RenoFiDesignCard id={id} />}
        </Box>

        <ul>
          {isAuthenticated(id) ? (
            <StyledLink onClick={logout}>
              <NavigationItem>
                <NavigationIcon>
                  <LogoutIcon />
                </NavigationIcon>
                Logout
              </NavigationItem>
            </StyledLink>
          ) : (
            <StyledLink onClick={redirectToLogin}>
              <NavigationItem>
                <NavigationIcon>
                  <LoginIcon />
                </NavigationIcon>
                Login
              </NavigationItem>
            </StyledLink>
          )}
        </ul>
      </Flex>
    </NavbarWrapper>
  );
};

export default NavbarDesktop;
