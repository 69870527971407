export default [
  {
    question: 'How does this work?',
    answer:
      'To get started, simply schedule a brief call with a Cottage advisor to share more about your project and your needs. If you like what we have to offer, you’ll be invited to the platform where you can see your pro matches, invite them to bid and review their offers. Start matching with top pros near you based on your priorities, location and project type.',
    open: false,
  },
  {
    question:
      'To use this service, do I have to also use a RenoFi loan for my project?',
    answer:
      'No! You can find the perfect pro with Cottage without any obligation to use RenoFi to find financing solutions. We think these services go hand-in-hand which is why RenoFi and Cottage have joined forces to remove the hassle in renovation planning, but each service is available to all renovators!',
    open: false,
  },
  {
    question: 'How are these pros verified?',
    answer: `Cottage verifies pros via interviews, reference checks and reviews of previous projects, and  takes reasonable steps to verify that pros have applicable licenses and insurance/bonding. Cottage collects data on pro performance, including timeliness, cost and owner satisfaction ratings, and factors this data into its pro review process. 
      
      Cottage does not control or guarantee the work performed by pros.`,
    open: false,
  },
  {
    question: 'What is Cottage?',
    answer:
      'Cottage is a platform which enables homeowners to design, build and renovate their properties. Founded in San Francisco, Cottage and RenoFi joined forces in late 2024 to build the renovation platform of the future where you can find the best pro for your project as well as the right financing solution.',
    open: false,
  },
  {
    question: 'Why is this service free?',
    answer:
      'We make money by helping pros connect with homeowners who are ready to renovate, as well as by underwriting the projects and helping provide funding. ',
    open: true,
  },
];
