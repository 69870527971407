import React from 'react';

import PropTypes from 'prop-types';
import {Flex} from 'rebass';

import {GoogleStarsIcon} from '@renofi/components/src/Icons';
import {useScreenSize} from '@renofi/utils';

import {CTAHeading, CTAWrapper, ImageContainer} from './styled';

const ScheduleBar = ({children, card, message}) => {
  const {isDesktop} = useScreenSize();

  return (
    <>
      <CTAWrapper>
        <Flex minWidth={200} mb={[24, 0]}>
          <ImageContainer inset={['-12px 0 0 0', '-12px 0 0 -40px']}>
            {card}
          </ImageContainer>
        </Flex>
        <Flex flexDirection={'column'}>
          <CTAHeading>{message}</CTAHeading>
          {children}
        </Flex>
        <Flex width={isDesktop ? 120 : 'auto'}>
          <ImageContainer inset={['0', '30px 30px auto auto']}>
            <GoogleStarsIcon />
          </ImageContainer>
        </Flex>
      </CTAWrapper>
    </>
  );
};

ScheduleBar.propTypes = {
  children: PropTypes.node,
  card: PropTypes.node,
  message: PropTypes.string.isRequired,
};

export default ScheduleBar;
