import styled from '@emotion/styled';
import {Flex, Text} from 'rebass';

import {renofiBlue} from '@renofi/theme';
import {mediaQuery} from '@renofi/utils';

export const ProsWrapper = styled(Flex)(() =>
  mediaQuery({
    flexDirection: ['column', 'row'],
    marginTop: ['20px', '40px'],
    padding: [0, '0 30px'],
    width: '100%',
    gap: ['16px', 0],
  }),
);

export const ProsReverse = styled(Flex)(() =>
  mediaQuery({
    flexDirection: ['column-reverse', 'row'],
    gap: ['16px', 0],
    flex: 2,
  }),
);

export const ProsItem = styled(Flex)({
  flex: 1,
  flexDirection: 'column',
  borderRadius: '12px',
  border: '1px solid rgba(33, 47, 73, 0.10)',
  justifyContent: 'space-evenly',
});

export const CentralProsItem = styled(ProsItem)(() =>
  mediaQuery({
    margin: [0, '-20px'],
    overflow: 'hidden',
    backgroundColor: '#fff',
    zIndex: 1,
  }),
);

export const ProsItemLeft = styled(ProsItem)(() =>
  mediaQuery({
    paddingLeft: [0, '20px'],
  }),
);

export const ProsItemHeader = styled(Flex)({
  width: '100%',
  padding: '22px',
  flexDirection: 'row',
  gap: '12px',
  borderBottom: '1px solid rgba(33, 47, 73, 0.10)',
});

export const AvatarWrapper = styled(Flex)({
  borderRadius: '25px',
  border: '3px solid #E5F5FF',
});

export const ProsItemDetails = styled(Flex)({
  flexDirection: 'column',
  padding: '22px',
});

export const ProsItemPriceWrapper = styled(Flex)({
  flexDirection: 'row',
  gap: '8px',
});

export const ProsItemPrice = styled(Flex)({
  fontSize: '24px',
  flexDirection: 'row',
});

export const Label = styled(Flex)({
  flexDirection: 'row',
  padding: '4px 8px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '5px',
  borderRadius: '17px',
  fontSize: '12px',
  lineHeight: 'normal',
});

export const BlueLabel = styled(Label)({
  background: '#E5F5FF',
});

export const GreenLabel = styled(Label)({
  background: '#E7F9E9',
});

export const YellowLabel = styled(Label)({
  background: '#FFF7E9',
});

export const ProsItemBenefits = styled(Flex)({
  flexDirection: 'column',
  padding: '20px 0 0 0',
  gap: '12px',
});

export const ProsItemBenefit = styled(Flex)({
  flexDirection: 'row',
  gap: '12px',
  alignItems: 'center',
  fontSize: '14px',
  color: '#0E1D3F',
  opacity: '0.6',
});

export const ProsSuccess = styled(Flex)(() =>
  mediaQuery({
    flexDirection: ['column', 'row'],
    gap: '16px',
    padding: ['0 0 20px 0', '40px 20px'],
  }),
);

export const ProsSuccessItem = styled(Flex)({
  flexDirection: 'row',
  flex: 1,
  alignItems: 'flex-start',
  gap: '16px',
  padding: '10px',
});

export const ProsSuccessItemTitle = styled(Text)({
  fontSize: '16px',
  fontWeight: '700',
  color: renofiBlue,
});

export const ProsSuccessItemDescription = styled(Flex)({
  flexDirection: 'row',
  gap: '12px',
  alignItems: 'center',
  fontSize: '14px',
  color: '#0E1D3F',
  opacity: '0.6',
});
